.textArea {
  min-width: 350px;
  min-height: 200px;
}
.button {
  margin-top: 15px;
  background-color: #e7e7e7;
  color: black;
}
.mapImage {
  height: 50%;
  width: 60%;
  float: right;
  margin-right: 1%;
  align-self: center;
  border: solid black 1px;
}
.tittleBar {
  background-color:rgb(5, 65, 99);
  color: white;
  text-align: center;
}
.container {
  background-color: white;
  border-style: groove;
}
.center {
  align-items: center;
}
label {
  width: 200px;
  margin-top: 15px;
  display: inline-block;
}
.selectWidth {
  width: 178px;
}
#projectInfoTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#projectInfoTable td, #projectInfoTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#projectInfoTable tr:nth-child(even){background-color: #f2f2f2;}

#projectInfoTable tr:hover {background-color: #ddd;}

#projectInfoTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgb(5, 65, 99);
  color: white;
}
